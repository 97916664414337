var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vipItem"},[(_vm.vipList.length > 0)?_c('swiper',{ref:"vipSwiper",staticClass:"vipSwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.vipList),function(item){return _c('swiper-slide',{key:item.productID,staticClass:"swiperSlide",class:{ activeVip: item.productID == _vm.selectedItem.productID }},[_c('div',{staticClass:"vipCard"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.productID == _vm.selectedItem.productID),expression:"item.productID == selectedItem.productID"}],ref:"vipImg",refInFor:true,staticClass:"vipImg",attrs:{"src":_vm.defaultImg,"addres":item.bgImg,"alt":""},on:{"~load":function($event){return _vm.imgLoad($event)},"~error":function($event){return _vm.imgError($event)}}}),_c('img',{ref:"defaultBg",refInFor:true,staticClass:"defaultBg",attrs:{"src":_vm.defaultImg,"addres":item.bgImgType1,"alt":""},on:{"~load":function($event){return _vm.imgLoad($event)},"~error":function($event){return _vm.imgError($event)}}})]),(
          _vm.isNewUser &&
          item.productType == 5 &&
          item.productID == _vm.selectedItem.productID
        )?_c('div',{staticClass:"countdown"},[_c('span',[_vm._v(_vm._s(_vm.timeStr))])]):_vm._e()])}),(_vm.vipList.length != 1)?_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}):_vm._e()],2):_vm._e(),(
      _vm.appConfig.advanceStatus &&
      _vm.appConfig.advanceStatus.activityStatus &&
      _vm.activityDetail.status != 4
    )?_c('div',{staticClass:"persalebox",on:{"click":function($event){return _vm.$router.push('/presale')}}},[_c('ImgDecypt',{key:_vm.activityDetail.status == 1 ||
        _vm.activityDetail.status == 2 ||
        _vm.activityDetail.status == 3
          ? _vm.presaleCard.bgImgType1
          : _vm.presaleCard.bgImg,staticClass:"presaleCard",attrs:{"src":_vm.activityDetail.status == 1 ||
        _vm.activityDetail.status == 2 ||
        _vm.activityDetail.status == 3
          ? _vm.presaleCard.bgImgType1
          : _vm.presaleCard.bgImg}})],1):_vm._e(),_c('div',{staticClass:"vipDesc"},[_vm._v(" 会员特权 "),_c('span',[_vm._v(_vm._s(_vm.selectedItem.privilegeDesc))])]),_c('div',{staticClass:"vipRights"},_vm._l((_vm.selectedItem.privilege),function(rItem,rIndex){return _c('div',{key:rIndex,staticClass:"rightsItem"},[_c('ImgDecypt',{key:rItem.img,staticClass:"rightsImg",attrs:{"src":rItem.img}}),_c('div',{staticClass:"privilegeName"},[_vm._v(_vm._s(rItem.privilegeName))])],1)}),0),(_vm.selectedItem.isFinalPay && !_vm.checkTime(_vm.selectedItem.startTime))?_c('div',{staticClass:"payBtn",on:{"click":function($event){return _vm.$toast('尾款支付暂未开放，请您耐心等待~')}}},[_vm._v(" 已预定 请在"+_vm._s(_vm.handleDate(_vm.selectedItem.startTime))+"支付尾款 ")]):_c('div',{staticClass:"payBtn",on:{"click":_vm.showPay}},[_vm._v(" ¥"+_vm._s(parseInt(_vm.selectedItem.discountedPrice / 10 || 0))+"/立即开通 ")]),_c('div',{staticClass:"payTip"},[_vm._v(" 支付中如有问题，请咨询"),_c('router-link',{attrs:{"tag":"span","to":"/customerService"}},[_vm._v("在线客服")])],1),_c('div',{staticClass:"payQuestionTitle"},[_vm._v("帮助中心")]),_c('div',{staticClass:"payQuestion"},[_c('router-link',{staticClass:"questionItem",attrs:{"tag":"div","to":"/duplicatePay"}},[_c('div',[_vm._v("如果重复购买了会员怎么办？")]),_c('svg-icon',{staticClass:"cellIcon",attrs:{"iconClass":"rightArrow"}})],1),_c('router-link',{staticClass:"questionItem",attrs:{"tag":"div","to":"/notVip"}},[_c('div',[_vm._v("支付成功了，但是没有显示会员卡？")]),_c('svg-icon',{staticClass:"cellIcon",attrs:{"iconClass":"rightArrow"}})],1),_c('router-link',{staticClass:"questionItem",attrs:{"tag":"div","to":"/payfail"}},[_c('div',[_vm._v("通常会有哪些原因造成支付失败？")]),_c('svg-icon',{staticClass:"cellIcon",attrs:{"iconClass":"rightArrow"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }